var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        [
          _c(
            "v-flex",
            {
              staticClass: "alertContainer",
              attrs: { "mt-5": "", xs10: "", md5: "" }
            },
            [
              !_vm.reasonGiven
                ? _c(
                    "v-card",
                    { staticClass: "alertContainer__content" },
                    [
                      _c("h1", [
                        _vm._v(
                          "Bevestig de deadline van " + _vm._s(_vm.reportNumber)
                        )
                      ]),
                      _c("p", [
                        _vm._v(
                          "Geef hier aan of je het dossier binnen de gestelde termijn kunt opleveren."
                        )
                      ]),
                      _c("p", [
                        _vm._v(
                          " Wanneer je verwacht dat dit niet zal lukken, vraag hier dan direct een nieuwe deadline aan bij IMG. Selecteer altijd een reden, en onderbouw je verzoek. Niet (of slecht) onderbouwde verzoeken worden afgewezen. "
                        )
                      ]),
                      _c("p", [
                        _vm._v(
                          "Kun je bevestigen dat je het dossier uiterlijk op " +
                            _vm._s(_vm._f("dateFormatTime")(_vm.date)) +
                            " inlevert?"
                        )
                      ]),
                      _c(
                        "v-radio-group",
                        {
                          attrs: { mandatory: false },
                          model: {
                            value: _vm.radioGroup,
                            callback: function($$v) {
                              _vm.radioGroup = $$v
                            },
                            expression: "radioGroup"
                          }
                        },
                        [
                          _c("v-radio", { attrs: { value: "yes" } }, [
                            _c(
                              "div",
                              {
                                attrs: { slot: "label", value: "yes" },
                                slot: "label"
                              },
                              [_vm._v("Ja, dat gaat mij lukken!")]
                            )
                          ]),
                          _c("v-radio", { attrs: { value: "no" } }, [
                            _c(
                              "div",
                              {
                                attrs: { slot: "label", value: "no" },
                                slot: "label"
                              },
                              [
                                _vm._v(
                                  "Nee, ik wil een nieuwe deadline aanvragen"
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      ),
                      _vm.radioGroup === "no"
                        ? [
                            _vm.report && _vm.report.deadline_at
                              ? _c("p", [
                                  _vm._v(" Huidige deadline "),
                                  _c("br"),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("dateFormatTime")(
                                          _vm.report.deadline_at
                                        )
                                      )
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _c(
                              "v-layout",
                              { attrs: { wrap: "" } },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c(
                                      "v-menu",
                                      {
                                        ref: "dialog",
                                        attrs: {
                                          "close-on-content-click": false,
                                          lazy: "",
                                          "nudge-right": "100",
                                          "full-width": "",
                                          "max-width": "290px",
                                          "min-width": "290px"
                                        },
                                        model: {
                                          value: _vm.isEditingDate,
                                          callback: function($$v) {
                                            _vm.isEditingDate = $$v
                                          },
                                          expression: "isEditingDate"
                                        }
                                      },
                                      [
                                        _c("v-text-field", {
                                          staticClass: "input input-date",
                                          attrs: {
                                            slot: "activator",
                                            placeholder: "DD-MM-JJJJ",
                                            label: "Gewenste nieuwe deadline",
                                            "error-messages":
                                              _vm.dateErrorMessage
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.dateFromTextField(
                                                $event
                                              )
                                            }
                                          },
                                          slot: "activator",
                                          model: {
                                            value: _vm.dateFormatted,
                                            callback: function($$v) {
                                              _vm.dateFormatted = $$v
                                            },
                                            expression: "dateFormatted"
                                          }
                                        }),
                                        _c("v-date-picker", {
                                          ref: "picker",
                                          attrs: {
                                            locale: "nl-nl",
                                            min: "1910-01-01",
                                            color: "#837f16",
                                            "first-day-of-week": "1"
                                          },
                                          on: {
                                            change: function($event) {
                                              _vm.isEditingDate = false
                                            }
                                          },
                                          model: {
                                            value: _vm.chosenDate,
                                            callback: function($$v) {
                                              _vm.chosenDate = $$v
                                            },
                                            expression: "chosenDate"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        placeholder: "Reden van vertraging",
                                        items: _vm.lateReasons,
                                        "item-text": "name",
                                        "item-value": "id"
                                      },
                                      model: {
                                        value: _vm.selectedReason,
                                        callback: function($$v) {
                                          _vm.selectedReason = $$v
                                        },
                                        expression: "selectedReason"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c("div", { staticClass: "label" }, [
                                      _vm._v(
                                        "Onderbouwing aan IMG voor aanleiding vertraging"
                                      )
                                    ]),
                                    _c("v-textarea", {
                                      attrs: { label: "Toelichting" },
                                      model: {
                                        value: _vm.deadlineReasonComment,
                                        callback: function($$v) {
                                          _vm.deadlineReasonComment = $$v
                                        },
                                        expression: "deadlineReasonComment"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e(),
                      _c(
                        "MiButton",
                        {
                          attrs: { round: "", disabled: _vm.isDisabled },
                          nativeOn: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.save($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.radioGroup === "no"
                                ? "Verzoek voor nieuwe deadline indienen bij IMG"
                                : "Bevestigen"
                            )
                          )
                        ]
                      )
                    ],
                    2
                  )
                : _vm._e(),
              _vm.reasonGiven
                ? _c(
                    "v-card",
                    { staticClass: "alertContainer__content" },
                    [
                      _c("h1", [_vm._v("Bedankt voor je medewerking!")]),
                      _c("p", [
                        _vm._v(
                          "Klik op de onderstaande knop om direct in te loggen."
                        )
                      ]),
                      _c("MiButton", { attrs: { round: "", to: "/login" } }, [
                        _vm._v("Inloggen")
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
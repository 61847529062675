import { Component, Vue, Watch } from 'vue-property-decorator';
import { Report } from '@/models/Report';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';
import { LateReason } from '@/models/LateReason';
import { AlertSystem } from '@/models/AlertSystem';
import { DateTime } from 'luxon';
import { isValidDate, setFormattedDatePickerValue, dateErrorMessage } from '@/support/String';

@Component<AlertConfirmation>({})
export default class AlertConfirmation extends Vue {
  public $pageTitle = 'Alert confirmation';

  protected report: Report | null = null;

  protected radioGroup = 'yes';

  protected lateReasons: any[] = [];

  protected selectedReason: string[] | null = null;

  protected chosenDate = '';

  protected deadlineReasonComment = '';

  protected reportId = '';

  protected reportNumber = '';

  protected date = '';

  protected userToken = '';

  protected reasonGiven = false;

  protected isEditingDate = false;

  public mounted() {
    this.chosenDate = DateTime.local().toFormat('yyyy-MM-dd');
    this.initialize();
  }

  public initialize() {
    this.getRejectReasons();
    this.date = (this.$route.query.planned_at as string);
    this.userToken = (this.$route.query.token as string);
    this.reportId = (this.$route.query.report as string);
    this.reportNumber = (this.$route.query.report_number as string);
    this.getReport();
  }

  protected getReport() {
    if (! this.reportId) {
      return;
    }

    new Report()
      .find(this.reportId)
      .then((report: Report) => {
        this.report = report;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getRejectReasons() {
    new LateReason()
      .filter({ type: 'damage' })
      .all()
      .then((response: any) => {
        this.lateReasons = response;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  // DATE
  protected dateFormatted: string | null = null;

  protected dateErrorMessage = '';

  protected dateFromDatePicker() {
    if (this.chosenDate) {
      this.dateFormatted = setFormattedDatePickerValue(this.chosenDate, 'yyyy-LL-dd', 'dd-LL-yyyy');
      this.dateErrorMessage = ! isValidDate(this.dateFormatted) ? dateErrorMessage : '';
    }
  }

  protected dateFromTextField(value: string) {
    this.dateErrorMessage = ! isValidDate(value) ? dateErrorMessage : '';
    this.chosenDate = setFormattedDatePickerValue(value);
  }

  @Watch('chosenDate')
  protected dateChanged() {
    this.dateFromDatePicker();
  }

  @Watch('isEditingDate')
  protected isEditingApprovedAtChanged() {
    if (! this.isEditingDate) {
      this.dateFromDatePicker();
    }
  }

  protected get isDisabled(): boolean {
    if (this.radioGroup === 'no') {
      return ! DateTime.fromString(this.chosenDate, 'yyyy-LL-dd').isValid
      || ! this.selectedReason
      || ! this.chosenDate;
    }

    return false;
  }

  protected save() {
    if (this.radioGroup === 'yes') {
      this.reasonGiven = true;
      return;
    }

    const payload: any = {
      security_hash: this.userToken,
      is_late: this.radioGroup === 'no',
    };

    if (this.radioGroup === 'no') {
      if (this.isDisabled) {
        return;
      }
      payload.late_reason = this.selectedReason;
      payload.requested_deadline_at = this.chosenDate;
      payload.request_deadline_comment = this.deadlineReasonComment;
    }

    new AlertSystem()
      .postLateReason(this.reportId, payload)
      .then((response: any) => {
        this.reasonGiven = true;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  @Watch('$route')
  public routeChanged(to: any, from: any) {
    this.initialize();
  }
}
